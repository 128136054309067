import React, { Component } from "react";
import { Form, Col, Button, Figure, Container, Row } from "react-bootstrap";

import {
  createBannerv2,
  updateBannerV2,
  updateBanner,
} from "../../../services/serviceBanner";
import { getAllBannerType } from "../../../services/serviceBannerType";
import { getAllLanguages } from "../../../services/serviceLanguages";
import {
  getAllShow,
  getShowsDropDowns,
  getShow,
} from "../../../services/serviceShows";
import { getEpisode } from "../../../services/serviceEpisodes";
import {
  getAllCategory,
  getCategoryDropDowns,
} from "../../../services/serviceCategories";
import {
  getAllChannel,
  getChannelDropdown,
} from "../../../services/serviceChannels";
import { ToastContainer, toast } from "react-toastify";
import { allLiveShowDropdown } from "../../../services/serviceLive";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Select } from "antd";
import axios from "axios";

class AddBannerv2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bannerBackgroundImageView: undefined,
      background_image: "",
      language: "",
      action_content: "",
      action_slug: "",
      content_slug: "",
      web_url: "NA",
      action: [],
      languages: [],
      short_url: "",
      background_image_v2_url: "",
      start_datetime: "",
      end_datetime: "",
      showdata: [],
      epidata: [],
      chadata: [],
      catdata: [],
      Liveshowdata: [],
      showDisplay: false,
      episodeDisplay: false,
      channelDisplay: false,
      categoryDisplay: false,
      webDisplay: false,
      sequence: "",
      isLoading: false,
      pulishDisabled: false,
      is_active: true,
      is_recurring: false,
      frequency: "",
      submitDisable: false,
      ContentData: [],
    };
  }
  componentDidMount = () => {
    if (this.props.data) {
      this.setState({ ...this.props.data }, () =>
        console.log(
          "state saved from props...",
          this.props.data,
          this.props.data.action_slug
        )
      );
      // return
      if (this.props.data.action_slug == "bact-af8ec0") {
        //show
        getShowsDropDowns()
          .then((response) => {
            let res = response.data;
            let showName = this.props.data.action_content;

            let GettingShowUniqueSlug = res.filter((data) =>
              data.title.toLowerCase().includes(showName.toLowerCase())
            );
            let ShowUniqueSlug = GettingShowUniqueSlug[0].uniqueSlug;
            this.setState({
              showdata: response.data,
              isLoading: false,
              action_content: ShowUniqueSlug,
              content_slug: "NA",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.props.data.action_slug == "bact-bfe5b6") {
        //episode
        getShowsDropDowns()
          .then((response) => {
            let res = response.data;
            let showName = this.props.data.content_slug;
            let GettingShowUniqueSlug = res.filter((data) =>
              data.title.toLowerCase().includes(showName.toLowerCase())
            );
            let ShowUniqueSlug = GettingShowUniqueSlug[0].uniqueSlug;

            this.setState({
              showdata: response.data,
              isLoading: false,
              content_slug: ShowUniqueSlug,
            });
            getShow(ShowUniqueSlug, this.state.is_active ? "True" : "False")
              .then((response) => {
                let res = response.data.episodes;
                let episodeName = this.props.data.action_content;
                let GettingEpisodeUniqueSlug = res.filter((data) =>
                  data.title.toLowerCase().includes(episodeName.toLowerCase())
                );
                let episodeUniqueSlug = GettingEpisodeUniqueSlug[0].uniqueSlug;
                // this.setState({ showdata: response.data, isLoading: false, content_slug: ShowUniqueSlug })
                this.setState({
                  epidata: response.data.episodes,
                  isLoading: false,
                  action_content: episodeUniqueSlug,
                });
                // console.log("hello")
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.props.data.action_slug == "bact-532c8a") {
        //channel
        getChannelDropdown()
          .then((response) => {
            let res = response.data;
            let ChannelName = this.props.data.content_slug;
            let GettingChannelUniqueSlug = res.filter((data) =>
              data.title.toLowerCase().includes(ChannelName.toLowerCase())
            );
            let ChannelUniqueSlug = GettingChannelUniqueSlug[0].uniqueSlug;
            this.setState({
              chadata: response.data,
              isLoading: false,
              content_slug: ChannelUniqueSlug,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.props.data.action_slug == "bact-df0a1e") {
        //category
        getCategoryDropDowns()
          .then((response) => {
            let res = response.data;
            let CategoryName = this.props.data.content_slug;
            let GettingCategoryUniqueSlug = res.filter((data) =>
              data.name.toLowerCase().includes(CategoryName.toLowerCase())
            );
            let CategoryUniqueSlug = GettingCategoryUniqueSlug[0].uniqueSlug;
            this.setState({
              catdata: response.data,
              isLoading: false,
              content_slug: CategoryUniqueSlug,
            });
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
        console.log("CAT");
      } else if (this.props.data.action_slug == "bact-cd9918") {
        //Liveshowdata
        allLiveShowDropdown()
          .then((response) => {
            this.setState({ Liveshowdata: response.data, isLoading: false });
            console.log("live shows", response.data);
          })
          .catch((error) => {
            console.log(error);
          });
        this.setState({ action_content: "NA" });
      }
    }
    this.setState({ isLoading: true });
    getAllBannerType()
      .then((response) => {
        console.log("bannerType", response.data.results);
        this.setState({ action: response.data.results }, () => {
          getAllLanguages()
            .then((response) => {
              this.setState({
                languages: response.data.results,
                isLoading: false,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  changeHandler = (e) => {
    this.setState({ [e?.target?.name]: e?.target?.value });
    if (e?.target?.name == "language") {
      this.setState({ action_slug: "" });
    }
    if (e?.target?.name == "action_slug" && e?.target?.value == "bact-af8ec0") {
      this.setState({ short_url: "" });
      this.setState({ content_slug: "" });
      this.setState({ action_content: "" });
      //show
      getShowsDropDowns()
        .then((response) => {
          this.setState({ showdata: response.data, isLoading: false });
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      this.setState({ content_slug: "NA" });
    } else if (
      e.target.name == "action_slug" &&
      e.target.value == "bact-bfe5b6"
    ) {
      this.setState({ short_url: "" });
      this.setState({ content_slug: "" });
      this.setState({ action_content: "" });
      //episode
      if (this.state.language === "") {
        toast.error(`select language first!`);
      } else {
        getShowsDropDowns(true, this.state.language)
          .then((response) => {
            this.setState({ showdata: response.data, isLoading: false });
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (
      e.target.name == "action_slug" &&
      e.target.value == "bact-532c8a"
    ) {
      this.setState({ short_url: "" });
      this.setState({ content_slug: "" });
      this.setState({ action_content: "" });
      //channel
      getChannelDropdown()
        .then((response) => {
          this.setState({ chadata: response.data, isLoading: false });
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      this.setState({ content_slug: "NA" });
    } else if (
      e.target.name == "action_slug" &&
      e.target.value == "bact-df0a1e"
    ) {
      this.setState({ short_url: "" });
      this.setState({ content_slug: "" });
      this.setState({ action_content: "" });
      //category
      getCategoryDropDowns()
        .then((response) => {
          this.setState({ catdata: response.data, isLoading: false });
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      this.setState({ content_slug: "NA" });
    } else if (
      e.target.name == "action_slug" &&
      e.target.value == "bact-cd9918"
    ) {
      this.setState({ short_url: " " });
      this.setState({ content_slug: "" });
      //Liveshowdata
      allLiveShowDropdown()
        .then((response) => {
          this.setState({ Liveshowdata: response.data, isLoading: false });
          console.log("live shows", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      this.setState({ action_content: "NA" });
    } else if (e.target.name == "web_url" && e.target.value == "bact-a08b66") {
      this.setState({ short_url: "" });
      this.setState({ content_slug: "" });
      this.setState({ action_content: "" });
      //web_url

      this.setState({
        action_content: "NA",
        short_url: "NA",
        content_slug: "NA",
        short_url: "NA",
      });
    } else if (
      e.target.name === "action_content" &&
      this.state.action_slug === "bact-af8ec0"
    ) {
      this.state.showdata.filter((data) =>
        data.uniqueSlug === e.target.value
          ? this.setState({
              short_url:
                data.shortUrl !== "" ? data.shortUrl : "empty shortUrl",
              submitDisable: data.shortUrl !== "" ? false : true,
            })
          : "kk"
      );
    } else if (
      e.target.name === "action_content" &&
      this.state.action_slug === "bact-bfe5b6"
    ) {
      this.state.epidata.filter((data) =>
        data.uniqueSlug === e.target.value
          ? this.setState({
              short_url:
                data.shortUrl !== "" ? data.shortUrl : "empty shortUrl",
              submitDisable: data.shortUrl !== "" ? false : true,
            })
          : "kk"
      );
    } else if (
      e.target.name === "action_content" &&
      this.state.action_slug === "bact-532c8a"
    ) {
      this.state.chadata.filter((data) =>
        data.uniqueSlug === e.target.value
          ? this.setState({
              short_url:
                data.shortUrl !== "" ? data.shortUrl : "empty shortUrl",
              submitDisable: data.shortUrl !== "" ? false : true,
            })
          : "kk"
      );
    } else if (
      e.target.name === "action_content" &&
      this.state.action_slug === "bact-df0a1e"
    ) {
      this.state.catdata.filter((data) =>
        data.uniqueSlug === e.target.value
          ? this.setState({
              short_url:
                data.shortUrl !== "" ? data.shortUrl : "empty shortUrl",
              submitDisable: data.shortUrl !== "" ? false : true,
            })
          : "kk"
      );
    }
  };
  changeEpisode = (e, name) => {
    console.log({ e, name }, "changeEpisode", e?.target);
    this.setState({ [e?.target?.name || name]: e?.target?.value || e });
    getShow(e?.target?.value || e, this?.state?.is_active ? "True" : "False")
      .then((response) => {
        let data = response.data.episodes;
        if (data.length > 0) {
          this.setState({ epidata: response?.data.episodes, isLoading: false });
          console.log(this.state.epidata);
        } else {
          this.setState({ epidata: [{ title: "No Data" }], isLoading: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onBannerBackgroundImageChange = (event) => {
    // this.setState({ bannerBackgroundImageView: undefined, background_image: '' })
    if (event.target.files[0]) {
      let reader = new FileReader();
      // if(event.target.files[0].type == "image/jpeg"){
      this.setState({ background_image: event.target.files[0] });
      console.log("image", event.target.files[0].type);
      reader.onload = (e) => {
        this.setState({ bannerBackgroundImageView: e.target.result });
        console.log("image result", e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  submitHandler = (e) => {
    this.setState({ pulishDisabled: true });
    e.preventDefault();
    // console.log(this.state)
    this.setState({ isLoading: true });
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "bannerBackgroundImageView":
        case "action":
        case "isLoading":
        case "showdata":
        case "Liveshowdata":
        case "epidata":
        case "chadata":
        case "catdata":
        case "background_image_v2_url":
          if (this.state.background_image_v2_url != "") {
            formData.append(`${key}`, value);
          }

          break;
        default:
          formData.append(`${key}`, value);
      }
    }
    {
      formData.append(`short_url`, this.state.short_url);
      formData.append(`action_content`, this.state.content_slug);
      formData.delete("content_slug");
      formData.append("content_slug", "NA");
    }
    for (var [key, value] of formData.entries()) {
      if (value === ("" || undefined || {})) {
        this.setState({ isLoading: false });
        if (key == "content_slug" && this.state.action_slug === "bact-af8ec0") {
          return toast.error(`Show name is required!`);
        }
        if (key == "content_slug" && this.state.action_slug === "bact-bfe5b6") {
          return toast.error(`Show name  is required!`);
        }
        if (
          key == "action_content" &&
          this.state.action_slug === "bact-bfe5b6"
        ) {
          return toast.error(`episode name is required!`);
        }
        if (key == "content_slug" && this.state.action_slug === "bact-532c8a") {
          return toast.error(`Channel name is required!`);
        }
        if (key == "content_slug" && this.state.action_slug === "bact-df0a1e") {
          return toast.error(`Category name is required!`);
        }
        if (key == "web_url" && this.state.action_slug === "bact-a08b66") {
          return toast.error(`web_url is required!`);
        }
        if (key === "short_url") {
          return toast.error(`short_url is required!`);
        }
        if (key === "language") {
          return toast.error(`language is required!`);
        }
        if (key === "action_slug") {
          return toast.error(`Banner action  is required!`);
        }
      }
      //   console.log(formData);
    }

    createBannerv2(formData)
      .then((response) => {
        if (response.status === 201) {
          toast.success(`New Banner v2 is created successfully.`);
          this.setState({
            isLoading: false,
            bannerBackgroundImageView: undefined,
            background_image: undefined,
            language: "",
            action_content: "",
            action_slug: "",
            content_slug: "",
            short_url: "",
            background_image_v2_url: "",
            pulishDisabled: false,
            is_active: true,
            end_datetime: "",
            start_datetime: "",
            is_recurring: false,
            frequency: "",
          });
          // window.location.reload(false);
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 400) {
          let err = "";
          for (let [key, value] of Object.entries(error.response.data)) {
            err = `${key} ${value[0]}. `;
          }
          toast.error(err);
          this.setState({ isLoading: false, pulishDisabled: false });
        }
        console.log(error);
        this.setState({ isLoading: false, pulishDisabled: false });
      });
  };
  updateHandler = (e) => {
    this.setState({ pulishDisabled: true });
    e.preventDefault();
    this.props.toggleBannerEditModal();
    console.log("updateHanderler", this.state);
    this.setState({ isLoading: true });
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "bannerBackgroundImageView":
        case "action":
        case "isLoading":
        case "showdata":
        case "Liveshowdata":
        case "epidata":
        case "chadata":
        case "catdata":
        case "background_image":
          if (this.state.background_image != "") {
            formData.append("background_image", value);
          }

          break;
        default:
          formData.append(`${key}`, value);
      }
    }
    for (var [key, value] of formData.entries()) {
      if (value === ("" || undefined || {})) {
        this.setState({ isLoading: false });
        if (key == "content_slug" && this.state.action_slug === "bact-af8ec0") {
          return toast.error(`Show name is required!`);
        }
        if (key == "content_slug" && this.state.action_slug === "bact-bfe5b6") {
          return toast.error(`Show name  is required!`);
        }
        if (
          key == "action_content" &&
          this.state.action_slug === "bact-bfe5b6"
        ) {
          return toast.error(`episode name is required!`);
        }
        if (key == "content_slug" && this.state.action_slug === "bact-532c8a") {
          return toast.error(`Channel name is required!`);
        }
        if (key == "content_slug" && this.state.action_slug === "bact-df0a1e") {
          return toast.error(`Category name is required!`);
        }
        if (key == "web_url" && this.state.action_slug === "bact-a08b66") {
          return toast.error(`web_url is required!`);
        }
        if (key === "short_url") {
          return toast.error(`short_url is required!`);
        }
        if (key === "language") {
          return toast.error(`language is required!`);
        }
        if (key === "action_slug") {
          return toast.error(`Banner action  is required!`);
        }
      }
      console.log(formData);
    }

    if (
      this.state.action_slug === "bact-af8ec0" &&
      this.state?.content_slug?.length > 0 &&
      this.state?.content_slug !== "NA"
    ) {
      formData.delete("action_content");
      formData.append("action_content", this.state?.content_slug);
    }

    //   const data1= {language:"ur"}
    updateBannerV2(this.state.uniqueSlug, formData, this.state.filter)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Banner updated successfully");
          this.setState({
            isLoading: false,
            bannerBackgroundImageView: undefined,
            background_image: "",
            language: "",
            action_content: "",
            action_slug: "",
            content_slug: "",
            short_url: "",
            background_image_v2_url: "",
            pulishDisabled: false,
            is_active: true,
            end_datetime: "",
            start_datetime: "",
            is_recurring: false,
            frequency: "",
          });
        }
      })
      .catch(() => {
        toast.error("Update failed, try again later");
        this.setState({ pulishDisabled: false });
      });
  };
  handelCheckbox = () => {
    this.setState({ is_active: !this.state.is_active });
  };
  handelrecurring = () => {
    this.setState({ is_recurring: !this.state.is_recurring });
  };
  handleDateChange = (date) => {
    this.setState({
      start_datetime: moment(date).format(),
      dateTime: date,
    });
  };
  handleEndDateChange = (date) => {
    this.setState({
      end_datetime: moment(date).format(),
      dateTime: date,
    });
  };

  selectHandler = (event, value) => {
    this.setState({ content_slug: event });
    if (this.state.action_slug === "bact-af8ec0") {
      this.state.showdata.filter((data) =>
        data.uniqueSlug === event
          ? this.setState({
              short_url:
                data?.shortUrl !== "" ? data?.shortUrl : "empty shortUrl",
              submitDisable: data?.shortUrl !== "" ? false : true,
            })
          : "kk"
      );
    } else if (this.state.action_slug === "bact-bfe5b6") {
      this.state.epidata.filter((data) =>
        data.uniqueSlug === event
          ? this.setState({
              short_url:
                data?.shortUrl !== "" ? data?.shortUrl : "empty shortUrl",
              submitDisable: data?.shortUrl !== "" ? false : true,
            })
          : "kk"
      );
    } else if (this.state?.action_slug === "bact-532c8a") {
      this.state.chadata.filter((data) =>
        data.uniqueSlug === event
          ? this.setState({
              short_url:
                data?.shortUrl !== "" ? data?.shortUrl : "empty shortUrl",
              submitDisable: data?.shortUrl !== "" ? false : true,
            })
          : "kk"
      );
    } else if (this.state?.action_slug === "bact-df0a1e") {
      this.state.catdata.filter((data) =>
        data?.uniqueSlug === event
          ? this.setState({
              short_url:
                data?.shortUrl !== "" ? data?.shortUrl : "empty shortUrl",
              submitDisable: data?.shortUrl !== "" ? false : true,
            })
          : "kk"
      );
    }
  };
  handleInputChange = (inputValue) => {
    this.setState({ isLoading: true });
    this.setState({ content_slug: "" });

    axios
      .post("https://prod.aawaz.com/search/", {
        search: inputValue, // Assuming inputValue is the search term
      })
      .then((response) => {
        // Extract the data from the response
        const searchData = response?.data;

        // Transform the data into the format required by React Select
        const options = searchData.map((item) => ({
          value: item.uniqueSlug,
          label: item.title,
        }));

        // Update the state with the new options
        this.setState({ ContentData: options });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    const {
      frequency,
      background_image_v2_url,
      short_url,
      action_slug,
      action_content,
      content_slug,
      action,
      showdata,
      epidata,
      catdata,
      chadata,
      web_url,
      Liveshowdata,
      language,
      languages,
      sequence,
      shows,
      shows_slug,
      Episode,
      slug,
      webDisplay,
      showDisplay,
      episodeDisplay,
      channelDisplay,
      categoryDisplay,
      Showslug,
      Episodeslug,
      channelslug,
      categoryslug,
      ContentData,
    } = this.state;
    return (
      <Container fluid>
        <Row>
          <Col>
            <ToastContainer position="top-center" />
            <h2 style={{ textAlign: "center" }}>Add New Banner v2</h2>
            <Form onSubmit={this.submitHandler}>
              <Form.Group>
                <Form.Row>
                  <Col>
                    <Form.Label>
                      Background Image Upload:{" "}
                      <span className="asterisk"> *</span>
                    </Form.Label>
                    <br></br>
                    {this.state.bannerBackgroundImageView ? (
                      <Figure>
                        <Figure.Image
                          src={this.state.bannerBackgroundImageView}
                          thumbnail
                          width={171}
                          height={180}
                        />
                      </Figure>
                    ) : (
                      <p>No image selected</p>
                    )}
                    <Form.File
                      name="background_image"
                      label="Background Image Upload"
                      accept="image/*"
                      onChange={this.onBannerBackgroundImageChange}
                      custom
                    />
                  </Col>
                </Form.Row>
              </Form.Group>
              <Form.Group>
                <Form.Row>
                  <Col>
                    <Form.Label>Background Image URL:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="background Image URL"
                      name="background_image_v2_url"
                      value={background_image_v2_url}
                      onChange={this.changeHandler}
                    />
                  </Col>
                </Form.Row>
              </Form.Group>

              <Form.Group>
                <Form.Row>
                  <Col>
                    <div>
                      <Form.Label>Start Date :</Form.Label>
                      <DatePicker
                        name="start_datetime"
                        selected={this.state.dateTime}
                        value={this.state.start_datetime}
                        onChange={this.handleDateChange}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={10}
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        autocomplete="off"
                      ></DatePicker>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <Form.Label> End Date :</Form.Label>
                      <DatePicker
                        name="end_datetime"
                        selected={this.state.dateTime}
                        value={this.state.end_datetime}
                        onChange={this.handleEndDateChange}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={10}
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        autocomplete="off"
                      ></DatePicker>
                    </div>
                  </Col>

                  <Col>
                    <div style={{ display: "flex" }}>
                      <Form.Label>
                        Is Active: <span className="asterisk"> </span>
                      </Form.Label>{" "}
                      <br></br>
                      <Form.Control
                        style={{
                          width: "14px",
                          height: "27px",
                          marginLeft: "12px",
                        }}
                        type="checkbox"
                        name="is_active"
                        checked={this.state.is_active}
                        onClick={this.handelCheckbox}
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      <Form.Label>
                        Is Recurring: <span className="asterisk"> </span>
                      </Form.Label>{" "}
                      <br></br>
                      <Form.Control
                        style={{
                          width: "14px",
                          height: "27px",
                          marginLeft: "12px",
                        }}
                        type="checkbox"
                        name="is_recurring"
                        checked={this.state.is_recurring}
                        onClick={this.handelrecurring}
                      />
                    </div>
                  </Col>
                </Form.Row>
              </Form.Group>
              <Form.Group>
                <Form.Row>
                  <Col>
                    <Form.Label>
                      Language: <span className="asterisk"> *</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="language"
                      value={language}
                      onChange={this.changeHandler}
                    >
                      <option defaultValue value="">
                        Select Language
                      </option>
                      {languages.map((data, index) => (
                        <option key={index} value={data.name}>
                          {data.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col>
                    <Form.Label>
                      Banner Action: <span className="asterisk"> *</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="action_slug"
                      value={action_slug}
                      onChange={this.changeHandler}
                    >
                      <option defaultValue value="">
                        Select Action
                      </option>
                      {action.map((data, index) => (
                        <option key={index} value={data.uniqueSlug}>
                          {data.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>

                  <Col>
                    {action_slug === "bact-af8ec0" ? (
                      <>
                        {/* show */}
                        <Form.Label>
                          show name: <span className="asterisk"> *</span>
                        </Form.Label>

                        <Select
                          mode={"single"}
                          showSearch
                          allowClear
                          filterOption={false}
                          defaultValue={
                            this?.props?.update
                              ? this?.props?.bannerShow
                              : this.state.content_slug
                          }
                          value={
                            !this?.props?.update
                              ? this.state.content_slug
                              : this?.props?.update &&
                                this.state.content_slug === "NA"
                              ? this?.props?.bannerShow
                              : this.state.content_slug
                          } // Use the correct state variable
                          // value={this.state.content_slug} // Use the correct state variable
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              minWidth: "400px",
                            }),
                          }}
                          placeholder="Search for show"
                          name="content_slug"
                          loading={this.state.isLoading}
                          onSearch={(e) => this.handleInputChange(e)}
                          onChange={this.selectHandler}
                          options={ContentData} // Use the correct state variable
                        />

                        {/* <Select
                          isMulti={false}
                          value={this.state.content_slug} // Use the correct state variable
                          styles={{
                            control: (styles) => ({ ...styles, width: 300 }),
                          }}
                          placeholder="Search for show"
                          name="content_slug"
                          isLoading={this.state.isLoading}
                          onSearch={(e) => this.handleInputChange(e)}
                          onChange={this.selectHandler}
                          options={ContentData} // Use the correct state variable
                        /> */}

                        {/* <Form.Control
                          as="select"
                          name="action_content"
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              width: 300,
                            }),
                          }}
                          value={action_content}
                          onChange={this.changeHandler}
                        >
                          {showdata.length == 0 ? (
                            <option defaultValue value="">
                              Loading...
                            </option>
                          ) : (
                            <option defaultValue value="">
                              Select Action
                            </option>
                          )}
                          {showdata.map((data, index) => (
                            <option key={index} value={data.uniqueSlug}>
                              {data.title}
                            </option>
                          ))}
                        </Form.Control> */}

                        <Form.Label>
                          Show Short url: <span className="asterisk"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Short url"
                          name="short_url"
                          value={short_url}
                          disabled={true}
                          onChange={this.changeHandler}
                        />
                      </>
                    ) : (action_slug === "bact-bfe5b6") & (language !== "") ? (
                      // episode
                      <>
                        <Form.Label>
                          show name: <span className="asterisk"> *</span>
                        </Form.Label>
                        <Select
                          mode={"single"}
                          showSearch
                          allowClear
                          filterOption={false}
                          defaultValue={
                            this?.props?.update
                              ? this?.props?.bannerShow
                              : this.state.content_slug
                          }
                          value={
                            !this?.props?.update
                              ? this.state.content_slug
                              : this?.props?.update &&
                                this.state.content_slug === "NA"
                              ? this?.props?.bannerShow
                              : this.state.content_slug
                          } // Use the correct state variable
                          // value={this.state.content_slug} // Use the correct state variable
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              minWidth: "400px",
                            }),
                          }}
                          placeholder="Search for show"
                          name="content_slug"
                          loading={this.state.isLoading}
                          onSearch={(e) => this.handleInputChange(e)}
                          onChange={(e) =>
                            this.changeEpisode(e, "content_slug")
                          }
                          options={ContentData} // Use the correct state variable
                        />
                        {/* <Form.Control
                          as="select"
                          name="content_slug"
                          value={content_slug}
                          onChange={this.changeEpisode}
                        >
                          {showdata.length == 0 ? (
                            <option defaultValue value="">
                              Loading...
                            </option>
                          ) : (
                            <option defaultValue value="">
                              Select Action
                            </option>
                          )}
                          {showdata.map((data, index) => (
                            <option key={index} value={data.uniqueSlug}>
                              {data.title}
                            </option>
                          ))}
                        </Form.Control> */}
                        {/* {content_slug != "" && epidata.length == 0 ? <p>Loading....</p>:null} */}
                        <Form.Label>
                          episode name: <span className="asterisk"> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="action_content"
                          value={action_content}
                          onChange={this.changeHandler}
                        >
                          {content_slug != "" && epidata.length == 0 ? (
                            <option defaultValue value="">
                              Loading...
                            </option>
                          ) : (
                            <option defaultValue value="">
                              Select Action
                            </option>
                          )}
                          {epidata.map((data, index) => (
                            <option key={index} value={data.uniqueSlug}>
                              {data.title}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Label>
                          Episode Short url:{" "}
                          <span className="asterisk"> *</span>
                        </Form.Label>
                        {this.state.submitDisable ? (
                          <p>
                            {" "}
                            please update shortUrl of respective
                            show/episode/etc..
                          </p>
                        ) : (
                          <Form.Control
                            type="text"
                            placeholder="Short url"
                            name="short_url"
                            value={short_url}
                            disabled={true}
                            onChange={this.changeHandler}
                          />
                        )}
                      </>
                    ) : action_slug === "bact-532c8a" ? (
                      <>
                        {/* {chadata.length == 0 ? <p>Loading....</p>:null} */}
                        <Form.Label>
                          channel name: <span className="asterisk"> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="action_content"
                          value={action_content}
                          onChange={this.changeHandler}
                        >
                          {chadata.length == 0 ? (
                            <option defaultValue value="">
                              Loading...
                            </option>
                          ) : (
                            <option defaultValue value="">
                              Select Action
                            </option>
                          )}
                          {chadata.map((data, index) => (
                            <option key={index} value={data.uniqueSlug}>
                              {data.title}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Label>
                          Channel Short url:{" "}
                          <span className="asterisk"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Short url"
                          name="short_url"
                          value={short_url}
                          disabled={true}
                          onChange={this.changeHandler}
                        />
                      </>
                    ) : action_slug === "bact-df0a1e" ? (
                      <>
                        {/* {catdata.length == 0 ? <p>Loading....</p>:null} */}
                        <Form.Label>
                          category name: <span className="asterisk"> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="action_content"
                          value={action_content}
                          onChange={this.changeHandler}
                        >
                          {catdata.length == 0 ? (
                            <option defaultValue value="">
                              Loading...
                            </option>
                          ) : (
                            <option defaultValue value="">
                              Select Action
                            </option>
                          )}
                          {catdata.map((data, index) => (
                            <option key={index} value={data.uniqueSlug}>
                              {data.name}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Label>
                          Category Short url:{" "}
                          <span className="asterisk"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Short url"
                          name="short_url"
                          value={short_url}
                          disabled={true}
                          onChange={this.changeHandler}
                        />
                      </>
                    ) : action_slug === "bact-cd9918" ? (
                      <>
                        {/* show */}
                        <Form.Label>
                          {" "}
                          Live show name: <span className="asterisk"> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="content_slug"
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              width: 300,
                            }),
                          }}
                          value={content_slug}
                          onChange={this.changeHandler}
                        >
                          {Liveshowdata.length == 0 ? (
                            <option defaultValue value="">
                              Loading...
                            </option>
                          ) : (
                            <option defaultValue value="">
                              Select Action
                            </option>
                          )}
                          {Liveshowdata.map((data, index) => (
                            <option key={index} value={data.uniqueSlug}>
                              {data.title}
                            </option>
                          ))}
                        </Form.Control>

                        {/* <Form.Label>Show Short url: <span className="asterisk"> *</span></Form.Label>
                                                                {this.state.submitDisable ? <p> please update shortUrl of respective show/episode/etc..</p> : <Form.Control type="text" placeholder="Short url" name="short_url" value={short_url} onChange={this.changeHandler} />} */}
                      </>
                    ) : action_slug === "bact-a08b66" ? (
                      <>
                        <Form.Label>
                          Web URL: <span className="asterisk"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Web URL"
                          name="web_url"
                          value={web_url}
                          onChange={this.changeHandler}
                        />
                      </>
                    ) : (
                      <p></p>
                    )}
                  </Col>

                  {/* { action_slug === "bact-af8ec0" ||action_slug === "bact-bfe5b6" ?  */}

                  <Col>
                    <Form.Label>
                      Sequence: <span className="asterisk"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Sequence"
                      name="sequence"
                      value={sequence}
                      onChange={this.changeHandler}
                    />
                  </Col>
                  <Col>
                    <Form.Label>
                      Frequency: <span className="asterisk"> </span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="frequency"
                      value={frequency}
                      onChange={this.changeHandler}
                    >
                      <option defaultValue value="">
                        Select Action
                      </option>
                      <option defaultValue value="daily">
                        Daily
                      </option>
                      <option defaultValue value="weekly">
                        Weekly
                      </option>
                      <option defaultValue value="monthly">
                        Monthly
                      </option>
                    </Form.Control>
                  </Col>
                </Form.Row>
              </Form.Group>
              {this.props.update ? (
                <Button
                  variant="primary"
                  onClick={this.updateHandler}
                  disabled={this.state.pulishDisabled}
                >
                  Save Changes
                </Button>
              ) : (
                <Button
                  type="submit"
                  disabled={this.state.submitDisable}
                  variant="info"
                >
                  Publish
                </Button>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default AddBannerv2;
