import React, { Component } from 'react';
import axios from 'axios';
import { Form, Col, Button, Figure } from 'react-bootstrap'
import { baseURL, updateCategory, getCategory } from "../../../services/serviceCategories";
import moment from 'moment';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import auth from '../../../services/authentication';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class AddCategories extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      weight: "",
      bannerImageView: "",
      bannerImageUrl: "",
      sequence: "",
      short_url: "",
      displayName: "",
      titleColor: "#000000",
      backgroundColor: "#000000",
      pulishDisabled: false,
      isEdit: false
    }
  }

  componentDidMount = () => {
    let uniqueSlug = this.props.match.params.id
    if (uniqueSlug !== "" && uniqueSlug !== undefined) {
      getCategory(uniqueSlug)
        .then((res) => {
          console.log(res.data)
          this.setState({
            backgroundColor: res.data.backgroundColor,
            bannerImageUrl: res.data.bannerImageUrl,
            displayName: res.data.iconImageUrl,
            featuredImageUrl: res.data.featuredImageUrl,
            iconImageUrl: res.data.iconImageUrl,
            name: res.data.name,
            sequence: res.data.sequence,
            short_url: res.data.shortUrl,
            shows: res.data.shows,
            titleColor: res.data.titleColor,
            uniqueSlug: res.data.uniqueSlug,
            weight: 1, isEdit: true
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  handleDateChange = date => {
    this.setState({
      published_on: moment(date).format(),
      dateTime: date
    });
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
    let show = this.state.editedCategory
    let editShow = {
      ...show,
      [e.target.name]: e.target.value
    }
    this.setState({ editedCategory: editShow })
  }
  onBannerShowImageChange = (event) => {
    this.setState({ bannerImageView: undefined, banner_image: '' })
    if (event.target.files[0]) {
      let reader = new FileReader();
      this.setState({ banner_image: event.target.files[0] })
      reader.onload = (e) => {
        this.setState({ bannerImageView: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  submitHandler = (e) => {
    this.setState({ pulishDisabled: true })
    e.preventDefault()
    // console.log(this.state)
    this.setState({ isLoading: true })
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case 'weight':
          formData.append(`${key}`, parseInt(value))
          break;
        case 'sequence':
          formData.append(`${key}`, parseInt(value))
          break;
        case 'displayName':
          break;
        case 'titleColor':
          formData.append(`${key}`, value)
          break;
        case 'backgroundColor':
          formData.append(`${key}`, value)
          break;
        case 'short_url':
          formData.append(`${key}`, value)
          break;
        case 'iconImageView':
          break;
        case 'featuredImageView':
          break;
        case 'bannerImageView':
          break;
        case 'bannerImageUrl':
          break;
        case 'iconImageUrl':
          break;
        default:
          formData.append(`${key}`, value)
      }
    }
    for (var [key, value] of formData.entries()) {
      if (value === '' || value === undefined || value === {}) {
        this.setState({ isLoading: false })
      }
      console.log(key + ': ' + value);
    }

    axios({
      url: `${baseURL}/category/`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data"
      },
      data: formData,
      auth: auth
    })
      .then(response => {
        if (response.status === 201) {
          toast.success(`New category ${response.data.name} created successfully.`)
          this.setState({
            isLoading: true,
            name: "",
            weight: "",
            bannerImageView: "",
            bannerImageUrl: "",
            banner_image: "",
            sequence: "",
            short_url: "",
            displayName: "",
            titleColor: "",
            backgroundColor: "",
            banner_image_url: "",
            pulishDisabled: false
          })
        }
        if (response.status === 400) {
          toast.error('Something went wrong, please try again')
          this.setState({ isLoading: false, pulishDisabled: false })
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          let err = "";
          for (let [key, value] of Object.entries(error.response.data)) {
            err = `${key} ${value[0]}. `
          }
          toast.error(err)
          this.setState({ isLoading: false, pulishDisabled: false })
        }
        console.log(error)
        this.setState({ isLoading: false, pulishDisabled: false })
      })

  }
  updateHandler = () => {
    this.setState({ isLoading: true })
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case 'weight':
          formData.append(`${key}`, parseInt(value))
          break;
        case 'sequence':
          formData.append(`${key}`, parseInt(value))
          break;
        case 'displayName':
          break;
        case 'titleColor':
          formData.append(`${key}`, value)
          break;
        case 'backgroundColor':
          formData.append(`${key}`, value)
          break;
        case 'short_url':
          formData.append(`${key}`, value)
          break;
        case 'iconImageView':
          break;

        case 'proxyList':
          break;
        case 'shows':
          break;
        default:
          formData.append(`${key}`, value)
      }
    }
    for (var [key, value] of formData.entries()) {
      if (value === '' || value === undefined || value === {}) {
        this.setState({ isLoading: false })
      }
    }
    updateCategory(this.state.uniqueSlug, formData)
      .then(response => {
        if (response.status === 200) {
          return toast.success("Category updated successfully")
        }
        else {
          return toast.error("Category updated unsuccessful, please try again later!")
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let err = "";
          for (let [key, value] of Object.entries(error.response.data)) {
            err = `${key} ${value[0]}`
          }
          return toast.error(err)
        } else {
          return toast.error(`category updated unsuccessful`)
        }
      })
  }


  render() {
    console.log(this.state.backgroundColor)
    const { name, weight, sequence, short_url, titleColor, displayName, backgroundColor, banner_image_url } = this.state
    return (
      <div>
        <ToastContainer
          autoClose={5000}
          hideProgressBar={false}
          position="top-center" />
        {this.state.isEdit ? <h2>Edit Category</h2> : <h2>Add New Category</h2>}

        <Form onSubmit={this.submitHandler}>
          <Form.Group>
            <Form.Row>
              <Col>
                <Form.Label>Category Name: <span className="asterisk"> *</span></Form.Label>
                <Form.Control type="text" placeholder="Enter Category Name here" name="name" value={name} onChange={this.changeHandler} />
              </Col>
            </Form.Row>

          </Form.Group>
          <Form.Group>
            <Form.Label>Banner Image : </Form.Label><br></br>
            {this.state.bannerImageView ?
              <Figure>
                <Figure.Image src={this.state.bannerImageView} thumbnail width={171} height={180} />
              </Figure> : <p>No image selected</p>}
            <Form.File name="banner_image" label="banner Image Upload" accept="image/*" onChange={this.onBannerShowImageChange} custom /><br /><br />
            <Form.Label>Banner Image URL: </Form.Label>
            <Form.Control type="text" placeholder="Banner Image URL" name="banner_image_url" value={banner_image_url} onChange={this.changeHandler} />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Row>
              <Col>
                <Form.Label>Weight: <span className="asterisk"> *</span></Form.Label>
                <Form.Control type="number" min="1" placeholder="Weight" name="weight" value={weight} onChange={this.changeHandler} />
              </Col>
              <Col>
                <Form.Label>sequence: <span className="asterisk"> *</span></Form.Label>
                <Form.Control type="number" min="1" placeholder="sequence" name="sequence" value={sequence} onChange={this.changeHandler} />
              </Col>

            </Form.Row>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Row>
              <Col>
                <Form.Label>Display Name:</Form.Label>
                <Form.Control type="text" placeholder="displayName" name="displayName" value={displayName} onChange={this.changeHandler} />
              </Col>
              <Col>
                <Form.Label>Title Color: </Form.Label>
                <Form.Control type="color" placeholder="titleColor" name="titleColor" value={titleColor} onChange={this.changeHandler} />
              </Col>
              <Col>
                <Form.Label>Background Color: </Form.Label>
                <Form.Control type="color" placeholder="backgroundColor" name="backgroundColor" value={backgroundColor} onChange={this.changeHandler} />
              </Col>
            </Form.Row>
          </Form.Group>

          <Form.Group>
            <Form.Label>Short URL: <span className="asterisk"> *</span></Form.Label>
            <Form.Control type="text" placeholder="Short URL" name="short_url" value={short_url} onChange={this.changeHandler} />
          </Form.Group>
          <Form.Group>
          </Form.Group>
          {this.state.isEdit ? <Button onClick={this.updateHandler} variant="info">Update</Button> : <Button type="submit" disabled={this.state.pulishDisabled} variant="info">Publish</Button>}
        </Form>
      </div>
    )
  }
}

export default AddCategories
