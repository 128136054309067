import http from '../http-common';
import auth from './authentication';
const baseURL = "https://prod.aawaz.com/api";

const getAllLanguages = () => {
    return http.get(`${baseURL}/app-language/`, { auth: auth });
};
const removeLanguages = (id) => {
    return http.delete(`${baseURL}/app-language/${id}/`, { auth: auth });
};

const createLanguages = data => {
    return http.post(`${baseURL}/app-language/`, { data: data }, { auth: auth });
    // return http.post(`${baseURL}/shows/`, { auth: auth, data: data });
    // return http.patch(`${baseURL}/shows/${id}/`, { data: data }, { auth: auth });
}
const getBySlugLanguages = slug => {
    return http.get(`${baseURL}/app-language/${slug}`, { auth: auth });
}
const EditLanguages = (id, data) => {
    // return http.patch(`${baseURL}/app-language/`, { data: data }, { auth: auth });
    return http.patch(`${baseURL}/app-language/${id}/`, { data: data }, { auth: auth });
}


export {
    getAllLanguages, removeLanguages, createLanguages, getBySlugLanguages, EditLanguages
}
