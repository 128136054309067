import http from "../http-common";
import auth from "./authentication";

const baseURL = "https://prod.aawaz.com/api";

const getTag = (id) => {
  return http.get(`${baseURL}/tagsv2/?paginate=False`, { auth: auth });
};

const createTag = (data) => {
  return http.post(`${baseURL}/tagsv2/`, { auth: auth, data: data });
};

const updateTag = (id, data) => {
  return http.patch(`${baseURL}/tagsv2/${id}/`, data, { auth: auth });
};

const removeTag = (id) => {
  return http.delete(`${baseURL}/tagsv2/${id}`, { auth: auth });
};

export { getTag, createTag, updateTag, removeTag };
