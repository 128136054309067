import http from '../http-common';
import auth from './authentication';
const baseURL = "https://prod.aawaz.com";

const CreateAd = (data) => {
    return http.post(`${baseURL}/aawaz_ad/advertisements/`, data, { auth: auth });
}

const Adlist = (page = 1) => {
    if (page === 1) {
        return http.get(`${baseURL}/aawaz_ad/advertisements/`, { auth: auth })
    }
    else {
        return http.get(`${baseURL}/aawaz_ad/advertisements/?page=${page}`, { auth: auth })

    }

}

const AllAdList = () => {
    return http.get(`${baseURL}/aawaz_ad/advertisements/?all=True`, { auth: auth })

}

const UpdateAd = (id, data) => {
    return http.patch(`${baseURL}/aawaz_ad/advertisements/${id}/`, data, { auth: auth });
}
const deleteAd = id => {
    return http.delete(`${baseURL}/aawaz_ad/advertisements/${id}/`, { auth: auth });
}


export {
    CreateAd,
    Adlist,
    AllAdList,
    UpdateAd,
    deleteAd,
    baseURL
};