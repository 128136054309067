
import React, { Component } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAlldashboardV3,
  removedashboardV3,
  updateListSequence,
} from "../../../services/serviceSections";
import AddDahboardV3 from "./AddDahboardV3";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { PencilSquare as AiOutlineEdit, Trash } from "react-bootstrap-icons";

export default class AllDashboardV3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DasboardData: [],
      EditId: "",
      edit: false,
      isDisableButton: true,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    getAlldashboardV3()
      .then((res) => {
        this.setState({ DasboardData: res.data, isDisableButton: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onDelete = (id, title) => {
    removedashboardV3(id)
      .then((res) => {
        toast.success(`dashboard ${title} deleted successfully!`);
        this.fetchData();
      })
      .catch((err) => {
        toast.error(`dashboard ${title} delete failed!`);
      });
  };

  editsection = (id) => {
    this.setState({ EditId: id, edit: true });
  };

  toggleDashboardEditModal = () => {
    this.setState({ edit: !this.state.edit });
  };

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const { DasboardData, isDisableButton } = this.state;
    const items = Array.from(DasboardData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    this.setState({ DasboardData: items, isDisableButton: false }, () => {
      const newData = DasboardData?.map((item) => item?.uniqueSlug);
      console.log({ newData });

      // Call a function or perform any actions with the updated array
    });
  };
  saveSequence = () => {
    const { isDisableButton, DasboardData } = this.state;
    const newData = DasboardData?.map((item) => item?.uniqueSlug);
    const commaSeparatedString = newData.join(", ");

    updateListSequence(commaSeparatedString)
      .then((res) => {
        this.setState({ isDisableButton: true });
        this.fetchData();
        toast.success("Action updated successfully.");

      })
      .catch((err) => {
        console.log(err);
        toast.error("Some erro occurs in action.");
      });
  };
  render() {
    const loader = (
      <ContentLoader backgroundColor="#c2c2c2">
        <rect x="0" y="56" rx="3" ry="3" width="150" height="4" />
        <rect x="0" y="72" rx="3" ry="3" width="100" height="4" />
      </ContentLoader>
    );
    const { DasboardData, isDisableButton } = this.state;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div>
          <ToastContainer
            autoClose={5000}
            hideProgressBar={false}
            position="top-center"
          />
          <h2>
            Section list({DasboardData?.length})
            <Button
              variant="primary"
              disabled={isDisableButton}
              onClick={() => this.saveSequence()}
            >
              Save Sequence
            </Button>
          </h2>

          <Modal
            size="xl"
            show={this.state.edit}
            onHide={this.toggleDashboardEditModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit show</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AddDahboardV3
                data={this.state.EditId}
                update={this.state.edit}
                toggleDashboardEditModal={this.toggleDashboardEditModal}
                fetchData={this.fetchData}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.toggleBannerEditModal}>
                Close
              </Button>
              {/* <Button variant="primary" onClick={this.updateHandler}>Save Changes</Button> */}
            </Modal.Footer>
          </Modal>
          <Table responsive hover>
            <thead>
              <tr key="header">
                <th scope="col">Sr.no</th>
                <th scope="col">section Name</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <Droppable droppableId="dashboardV3">
              {(provided) => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {!this.state.isLoading ? (
                    this.state.DasboardData.map((section, index) => (
                      <Draggable
                        key={section.uniqueSlug}
                        draggableId={section.uniqueSlug}
                        index={index}
                      >
                        {(provided) => (
                          <tr
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <td>{index + 1}</td>
                            <td>{section?.title}</td>

                            {section.title === "NO DATA" ? (
                              <td>{section?.title}</td>
                            ) : (
                              <td style={{
                                cursor: "pointer",
                              }}>
                                {/* <Button
                                  variant="primary"
                                  onClick={() =>
                                    this.editsection(section.uniqueSlug)
                                  }
                                > */}
                                {/* Edit section */}
                                <AiOutlineEdit
                                  style={{
                                    color: "blue",
                                    marginRight: "10px",
                                  }}
                                  onClick={() =>
                                    this.editsection(section.uniqueSlug)
                                  }
                                />
                                {/* </Button> */}
                                {/* <Button
                                  variant="danger"
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        `Are you sure you wish to delete dashboard ${section.title}?`
                                      )
                                    )
                                      this.onDelete(
                                        section.uniqueSlug,
                                        section.title
                                      );
                                  }}
                                >
                                  Delete
                                </Button> */}
                                <Trash
                                  title="Delete"
                                  style={{ color: "red" }}
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        `Are you sure you wish to delete dashboard ${section.title}?`
                                      )
                                    )
                                      this.onDelete(
                                        section.uniqueSlug,
                                        section.title
                                      );
                                  }}
                                />
                              </td>
                            )}
                          </tr>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <tr>
                      <td>{loader}</td>
                      <td>{loader}</td>
                      <td>{loader}</td>
                      <td>{loader}</td>
                    </tr>
                  )}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </Table>
        </div>
      </DragDropContext>
    );
  }
}
