import http from '../http-common'
import auth from './authentication'

const baseURL = "https://prod.aawaz.com/api";

const getAllBannerType = () => {
    return http.get(`${baseURL}/banner-actions/?limit=200`, { auth: auth });
};

const getBannerType = id => {
    return http.get(`${baseURL}/banner-actions/${id}/`, { auth: auth });
}

const createBannerType = data => {
    return http.post(`${baseURL}/banner-actions/`, { auth: auth, data: data });
}

const updateBannerType = (id, data) => {
    return http.patch(`${baseURL}/banner-actions/${id}/`, { auth: auth, data: data });
}

const removeBannerType = id => {
    return http.delete(`${baseURL}/banner-actions/${id}`, { auth: auth });
}


export {
    getAllBannerType, getBannerType, createBannerType, updateBannerType, removeBannerType, baseURL
}