import { Form, Col, Row, Figure, Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { createHeader, UpatdeHeader } from "../../../services/servicesHeader";
import { getAllPlatform } from "../../../services/servicesPlatform";

const AddHeader = ({ isEdit, data }) => {
  const [BackgroundImageView, setBackgroundImageView] = useState("");
  const [LogoImageView, setLogoImageView] = useState("");
  const [HeaderDecorationView, setHeaderDecorationView] = useState("");
  const [headerDecoration, setheaderDecoration] = useState("");
  const [logo, setlogo] = useState("");
  const [backgroundImage, setbackgroundImage] = useState("");
  const [platform, setplatform] = useState("");
  const [platformData, setplatformData] = useState([]);

  //   on page load api call
  useEffect(() => {
    getAllPlatform()
      .then((res) => {
        console.log("plategsaxas", res.data.results);
        setplatformData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    if (data) {
      setBackgroundImageView(data.backgroundImageUrl);
      setbackgroundImage(data.backgroundImageUrl);
      setLogoImageView(data.logoUrl);
      setlogo(data.logoUrl);
      setheaderDecoration(data.headerDecorationUrl);
      setHeaderDecorationView(data.headerDecorationUrl);
      setplatform(data.platform.uniqueSlug);
    }
  }, [data]);

  //   plateform chnage handeler
  const changeHandler = (e) => {
    setplatform(e.target.value);
  };

  //   images handeler
  const onBannerBackgroundImageChange = (event) => {
    if (event.target.files[0]) {
      let reader = new FileReader();
      if (event.target.name === "backgroundImage") {
        setbackgroundImage(event.target.files[0]);
        reader.onload = (e) => {
          setBackgroundImageView(e.target.result);
        };
      } else if (event.target.name === "headerDecoration") {
        setheaderDecoration(event.target.files[0]);
        reader.onload = (e) => {
          setHeaderDecorationView(e.target.result);
        };
      } else {
        setlogo(event.target.files[0]);
        reader.onload = (e) => {
          setLogoImageView(e.target.result);
        };
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  const submitHandler = (e) => {
    e.preventDefault();
    // this.setState({ isLoading: true });
    let formData = new FormData();
    let data = {
      platformSlug: platform,
      backgroundImage,
      headerDecoration,
      logo,
    };
    console.log("data", data);
    for (let [key, value] of Object.entries(data)) {
      switch (key) {
        case "BackgroundImageView":
        case "LogoImageView":
        case "HeaderDecorationView":
        case "isLoading":
          break;
        default:
          formData.append(`${key}`, value);
      }
    }
    createHeader(formData)
      .then((response) => {
        if (response.status === 201) {
          toast.success(`New Header is created successfully.`);
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 400) {
          let err = "";
          for (let [key, value] of Object.entries(error.response.data)) {
            err = `${key} ${value[0]}. `;
          }
          return toast.error(err);
        }
      });
  };
  const updateHandler = () => {
    // this.setState({ isLoading: true });
    let formData = new FormData();
    let updatedata = {
      platformSlug: platform,
      backgroundImage,
      headerDecoration,
      logo,
    };
    for (let [key, value] of Object.entries(updatedata)) {
      switch (key) {
        case "BackgroundImageView":
        case "LogoImageView":
        case "HeaderDecorationView":
        case "isLoading":
          break;
        default:
          formData.append(`${key}`, value);
      }
    }
    UpatdeHeader(data.uniqueSlug, formData)
      .then((response) => {
        toast.success(` Header update  successfully.`);
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 400) {
          let err = "";
          for (let [key, value] of Object.entries(error.response.data)) {
            err = `${key} ${value[0]}. `;
          }
          return toast.error(err);
          //   this.setState({ isLoading: false, pulishDisabled: false });
        }
        console.log(error);
        // this.setState({ isLoading: false, pulishDisabled: false });
      });
  };
  return (
    <div>
      <ToastContainer />
      <h1>Special Header </h1>
      <Form onSubmit={(e) => submitHandler(e)}>
        <Form.Group>
          <Form.Row>
            <Col>
              <Form.Label>
                Background Image Upload: <span className="asterisk"> *</span>
              </Form.Label>
              <br></br>
              {BackgroundImageView ? (
                <Figure>
                  <Figure.Image
                    src={BackgroundImageView}
                    thumbnail
                    width={171}
                    height={180}
                  />
                </Figure>
              ) : (
                <p>No Background Image selected</p>
              )}
              <Form.File
                name="backgroundImage"
                label="Background Image Upload"
                accept="image/*"
                onChange={(e) => onBannerBackgroundImageChange(e)}
                custom
                required
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Label>Header Image Upload:</Form.Label>
              <br></br>
              {HeaderDecorationView ? (
                <Figure>
                  <Figure.Image
                    src={HeaderDecorationView}
                    thumbnail
                    width={171}
                    height={180}
                  />
                </Figure>
              ) : (
                <p>No Header Image selected</p>
              )}
              <Form.File
                name="headerDecoration"
                label="Header Decoration Image Upload"
                accept="image/*"
                onChange={(e) => onBannerBackgroundImageChange(e)}
                custom
              />
            </Col>
            <Col>
              <Form.Label>Logo Image Upload:</Form.Label>
              <br></br>
              {LogoImageView ? (
                <Figure>
                  <Figure.Image
                    src={LogoImageView}
                    thumbnail
                    width={171}
                    height={180}
                  />
                </Figure>
              ) : (
                <p>No Logo Image selected</p>
              )}
              <Form.File
                name="logo"
                label="Logo Image Upload"
                accept="image/*"
                onChange={(e) => onBannerBackgroundImageChange(e)}
                custom
              />
            </Col>
            <Col>
              <Form.Label>
                Platform: <span className="asterisk">* </span>
              </Form.Label>
              <Form.Control
                as="select"
                name="platformSlug"
                value={platform}
                onChange={(e) => changeHandler(e)}
              >
                <option defaultValue value="">
                  Select a platform
                </option>
                {platformData.map((data, index) => (
                  <option key={index} value={data.uniqueSlug}>
                    {data.name}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Row>
        </Form.Group>
        {isEdit ? (
          <Button
            variant="primary"
            onClick={() => updateHandler()}
            // disabled={this.state.pulishDisabled}
          >
            update
          </Button>
        ) : (
          <Button
            type="submit"
            // disabled={this.state.submitDisable}
            variant="info"
          >
            Publish
          </Button>
        )}
      </Form>
    </div>
  );
};

export default AddHeader;
