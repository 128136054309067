import http from '../http-common';
import auth from './authentication';

const baseURL = "https://prod.aawaz.com/api";

const getAllBanner = () => {
    return http.get(`${baseURL}/banners/?all=True`, { auth: auth });
};
const getAllBannerV2 = (page = 1, filter) => {
    if (filter !== "" && filter !== undefined) {
        return http.get(`${baseURL}/banners-v2/?cms=True&is_active=${filter}&page=${page}`, { auth: auth });
    } else {
        return http.get(`${baseURL}/banners-v2/?cms=True&page=${page}`, { auth: auth });
    }
};

const getBannerDropDown = () => {
    return http.get(`${baseURL}/banners/?all=True&only_select=True`, { auth: auth });
};

const getBanner = id => {
    return http.get(`${baseURL}/banners/${id}/`, { auth: auth });
}

const createBanner = data => {
    return http.post(`${baseURL}/banners/`, data, {
        auth: auth, headers: { "Content-Type": "multipart/form-data" }
    });
}

const createBannerv2 = data => {
    return http.post(`${baseURL}/banners-v2/?cms=True`, data, {
        auth: auth, headers: { "Content-Type": "multipart/form-data" }
    });
}

const updateBanner = (id, data) => {
    return http.patch(`${baseURL}/banners/${id}/`, data, { auth: auth });
}
const updateBannerV2 = (id, data, filter) => {
    // if (filter === true) {
    return http.patch(`${baseURL}/banners-v2/${id}/?cms=True&is_active=${filter}`, data, { auth: auth });
    // } else {
    //     return http.patch(`${baseURL}/banners-v2/${id}/?cms=True&is_active=True`, data, { auth: auth });
    // }

}

const removeBanner = id => {
    return http.delete(`${baseURL}/banners/${id}/`, { auth: auth });
}

const removeBannerv2 = id => {
    return http.delete(`${baseURL}/banners-v2/${id}/?cms=True`, { auth: auth });
}

const removeAllBanner = () => {
    return http.delete(`${baseURL}/banners/`, { auth: auth });
}

const findByTitleBanner = title => {
    return http.get(`${baseURL}/banners/?title=${title}`, { auth: auth });
}
export {
    getAllBanner,
    getBannerDropDown,
    getBanner,
    createBanner,
    updateBanner,
    removeBanner,
    removeAllBanner,
    findByTitleBanner,
    createBannerv2,
    removeBannerv2,
    updateBannerV2,
    getAllBannerV2,
    baseURL
};
