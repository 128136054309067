import React, { Component } from 'react'
import { Form, Col, Button, Row, Table, Container, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import ContentLoader from "react-content-loader";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import {
    CreateAd,
    Adlist,
    AllAdList,
    UpdateAd,
    deleteAd
} from '../../../services/serviceAds'
import CreateAds from './CreateAd'
import ReactPaginate from 'react-paginate';

class AllAdLists extends Component {
    constructor(props) {
        super(props);
        this.state = {

            next: "",
            totalSections: 0,
            paginationCount: 0,
            currentPage: 1,
            AdList: [],
            editedData: {},
            editAd: false,
            isLoading: true,
        };
    }

    componentDidMount() {
        this.fetchAdListData()
    }
    fetchAdListData = (page) => {
        Adlist(page)
            .then(response => {
                // toast.success(`New section "${response.data.name}" created succesfully`)
                // this.setState({ editedSection: {} }, () => {
                //   this.toggleAddNewSectionHandler()
                //   this.fetchSectionData(this.state.currentPage)
                console.log(response.data.results)
                this.setState({ AdList: response.data.results, next: response.data.next, paginationCount: Math.ceil(response.data.count / 10), Items: response.data.results.items, totalSections: response.data.results.length, isLoading: false })
            })

            .catch(error => {
                // if (error.response.status === 400) {
                //   let err = "";
                //   for (let [key, value] of Object.entries(error.response.data)) {
                //     err = `${key}: ${value[0]}. `
                //   }
                //   toast.error(err)
                //   this.hideLoader()
                // }
                this.setState({isLoading:false})
                console.log(error)
            })
    }
    handlePageClick = (data) => {
        // this.showLoader()
        console.log("page", data)
        let selected = data.selected + 1;
        console.log(selected)
        this.props.history.replace("?page=" + selected)
        this.setState({ currentPage: selected, isLoading: true }, () => this.fetchAdListData(this.state.currentPage))

    }
    onDeleteAdList = (id, name) => {
        deleteAd(id)
            .then(response => {
                toast.success(`${name}deleted successfully`)
                this.fetchAdListData(this.state.currentPage)
            })

            .catch(error => {
                // if (error.response.status === 400) {
                //     let err = "";
                //     for (let [key, value] of Object.entries(error.response.data)) {
                //         err = `${key} ${value}. `
                //     }
                //     toast.error(err)
                // }
                console.log(error)
            })
    }
    editAdList = (data) => {
        this.toggleAdListEditModal()
        this.setState({ editedData: data })
        console.log("edited data live show", data)
    }
    toggleAdListEditModal = () => {
        this.setState({ editAd: !this.state.editAd })
    }

    render() {
        const loader = <ContentLoader backgroundColor="#c2c2c2"><rect x="0" y="56" rx="3" ry="3" width="150" height="4" /><rect x="0" y="72" rx="3" ry="3" width="100" height="4" /></ContentLoader>
        return (
            <div>
                <ToastContainer position="top-right" />
                <Container>
                    <Modal size="xl" show={this.state.editAd} onHide={this.toggleAdListEditModal} >
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Live Show</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <CreateAds editedData={this.state.editedData} update={true} toggleAdListEditModal={this.toggleAdListEditModal} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.toggleAdListEditModal}>Close</Button>
                            {/* <Button variant="primary" onClick={this.updateHandler}>Save Changes</Button> */}
                        </Modal.Footer>
                    </Modal>
                </Container>
                <Container>
                    <Row>

                        <Col className="centerAlign">

                            <Table responsive hover >
                                <thead>
                                    <tr key="header">
                                        <th scope="col">Sr.no</th>
                                        {/* <th scope="col">Featured Image</th> */}
                                        <th scope="col">Ad Name </th>
                                        {/* <th scope="col">Category</th> */}
                                        <th scope="col">Actions</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !this.state.isLoading ?
                                            this.state.AdList.map((Ad, index) =>
                                                <tr key={index}>
                                                    <td key={index}>{index + 1}</td>
                                                    <td>{Ad.title}</td>
                                                    <td>
                                                        <Button variant="primary" onClick={() => this.editAdList(Ad)}>Edit</Button>
                                                        <Button variant="danger" onClick={() => { if (window.confirm(`Are you sure you wish to delete show ${Ad.title}?`)) this.onDeleteAdList(Ad.uniqueSlug, Ad.title) }}>Delete</Button>
                                                    </td>
                                                </tr>
                                            ) :

                                            <tr>
                                                <td>{loader}</td>
                                                <td>{loader}</td>
                                                <td>{loader}</td>
                                                <td>{loader}</td>
                                                <td>{loader}</td>
                                                <td>{loader}</td>
                                                <td>{loader}</td>
                                            </tr>
                                    }



                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
                <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.paginationCount}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    forcePage={this.state.currentPage - 1}
                ></ReactPaginate>
            </div >
        )
    }
}

export default AllAdLists;