import http from "../http-common";
import auth from "./authentication";

const baseURL = "https://prod.aawaz.com/api";

const getAllShow = () => {
  return http.get(`${baseURL}/shows/?all=True`, { auth: auth });
};

const getShowsDropDowns = (filter, lang) => {
  if (
    filter !== "" &&
    filter !== undefined &&
    lang !== "" &&
    lang !== undefined
  ) {
    return http.get(
      `${baseURL}/shows/?only_select=True&all=True&language=${lang}`,
      { auth: auth }
    );
  } else if (filter !== "" && filter !== undefined) {
    return http.get(
      `${baseURL}/shows/?all=True&is_active=${filter}&only_select=True`,
      { auth: auth }
    );
  } else {
    return http.get(`${baseURL}/shows/?only_select=True&all=True`, {
      auth: auth,
    });
  }
};

const getAllShowPaginate = (page = 1) => {
  return http.get(`${baseURL}/shows/?page=${page}`, { auth: auth });
};

const getShow = (id, filter) => {
  if (filter === "False") {
    return http.get(`${baseURL}/shows/${id}/?is_active=${filter}`, {
      auth: auth,
    });
  } else {
    return http.get(`${baseURL}/shows/${id}`, { auth: auth });
  }
};

const createShow = (data) => {
  return http.post(`${baseURL}/shows/`, { auth: auth, data: data });
};

const updateShow = (id, data) => {
  return http.patch(`${baseURL}/shows/${id}/`, { data: data }, { auth: auth });
};

const removeShow = (id) => {
  return http.delete(`${baseURL}/shows/${id}`, { auth: auth });
};

const removeAllShow = () => {
  return http.delete(`${baseURL}/shows`, { auth: auth });
};

const findByTitleShow = (title) => {
  return http.get(`${baseURL}/shows?title=${title}`, { auth: auth });
};

export {
  getAllShow,
  getAllShowPaginate,
  getShow,
  createShow,
  updateShow,
  removeShow,
  removeAllShow,
  findByTitleShow,
  getShowsDropDowns,
  baseURL,
};
