import React, { Component } from 'react';
import { Form, Col, Button, Figure, Container, Row } from 'react-bootstrap';
import {
    CreateAd,
    Adlist,
    AllAdList,
    UpdateAd,
    deleteAd
} from '../../../services/serviceAds'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { FormDropdown } from 'semantic-ui-react';
class CreateAds extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: "",
            description: "",
            ad_type: "",
            audio_url: "",
            image: "",
            image_url: "",
            audio_file: "",
            ImageView: undefined,
            isLoading: false,
        }
    }

    componentDidMount = () => {
        if (this.props.editedData) {
            this.setState({
                title: this.props.editedData.title,
                description: this.props.editedData.description,
                ad_type: this.props.editedData.adType,
                audio_url: this.props.editedData.audioUrl,
                image_url: this.props.editedData.imageUrl,
                uniqueSlug: this.props.editedData.uniqueSlug

            })
        }
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onBannerBackgroundImageChange = (event) => {
        // this.setState({ ImageView: undefined, image: '' })
        if (event.target.files[0]) {
            let reader = new FileReader();
            // if(event.target.files[0].type == "image/jpeg"){
            this.setState({ image: event.target.files[0] })
            console.log("image", event.target.files[0].type)

            // }else{
            //     toast.error(`Invalid File format. The file formats supported are  jpg only`) 
            // }

            reader.onload = (e) => {
                this.setState({ ImageView: e.target.result });
                console.log("image result", e.target.result)
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }
    onAudioFileChange = (event) => {
        this.setState({ audio_file: event.target.files[0] })
    }


    submitHandler = (e) => {
        e.preventDefault()
        // console.log(this.state)
        this.setState({ isLoading: true })
        let formData = new FormData();
        for (let [key, value] of Object.entries(this.state)) {
            switch (key) {
                case 'ImageView':
                case 'image_url':
                    if (this.state.image_url != "") {
                        formData.append(`${key}`, value)
                    }

                    break;
                default:
                    formData.append(`${key}`, value)
            }
        }

        CreateAd(formData)
            .then(response => {
                if (response.status === 201) {
                    toast.success(`New Ad is created successfully.`)
                    this.setState({
                        title: "",
                        description: "",
                        ad_type: "",
                        audio_url: "",
                        image: "",
                        image_url: "",
                        ImageView: undefined,
                    })
                    // window.location.reload(false);
                }
            })
            .catch(error => {
                console.log(error.response)
                if (error.response.status === 400) {
                    let err = "";
                    for (let [key, value] of Object.entries(error.response.data)) {
                        err = `${key} ${value[0]}. `
                    }
                    toast.error(err)
                    this.setState({ isLoading: false })
                }
                console.log(error)
                this.setState({ isLoading: false })
            })

    }
    updateHandler = (e) => {
        e.preventDefault()
        this.props.toggleAdListEditModal()
        console.log("updateHanderler", this.state)
        this.setState({ isLoading: true })
        let formData = new FormData();
        for (let [key, value] of Object.entries(this.state)) {
            switch (key) {
                case 'ImageView':
                case 'isLoading':
                case "image":
                    if (this.state.image != "") {
                        formData.append("image", value)
                    }

                    break;
                default:
                    formData.append(`${key}`, value)
            }
        }
        for (var [key, value] of formData.entries()) {
            if (value === '' || value === undefined || value === {}) {
                this.setState({ isLoading: false })

            }
            //   console.log(formData);
        }
        //   const data1= {language:"ur"}
        UpdateAd(this.state.uniqueSlug, formData)
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        title: "",
                        description: "",
                        ad_type: "",
                        audio_url: "",
                        image: "",
                        image_url: "",
                        ImageView: undefined,
                        isLoading: false,
                    })
                    return toast.success(`Ad ${response.data.title}updated successfully`)
                }
            })
            .catch(() => {
                toast.error("Update failed, try again later")
            })
    }

    render() {
        const { image_url, title, description, ad_type, audio_file, audio_url } = this.state
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <ToastContainer position="top-center" />
                        <h2 style={{ textAlign: 'center' }}>Create New Ad</h2>
                        <Form onSubmit={this.submitHandler}>
                            <Form.Group>

                                {/* Title */}
                                <Form.Row>
                                    <Form.Label>Title:  <span className="asterisk"> *</span></Form.Label>
                                    <Form.Control type="text" placeholder="title" name="title" value={title} onChange={this.changeHandler} />
                                </Form.Row>
                                {/* discription */}
                                <Form.Row>
                                    <Form.Label>Discription:  <span className="asterisk"> *</span></Form.Label>
                                    <Form.Control type="text" placeholder="description" name="description" value={description} onChange={this.changeHandler} />
                                </Form.Row>
                                {/* Ad type */}
                                <Form.Row>
                                    <Form.Label>Ad Type:  <span className="asterisk"> *</span></Form.Label>
                                    {/* <Form.Control type="text" placeholder="Ad Type" name="ad_type" value={ad_type} onChange={this.changeHandler} /> */}
                                    <Form.Control as="select" name="ad_type" value={ad_type} onChange={this.changeHandler}>
                                        <option defaultValue value="">Select a Ad Type</option>
                                        <option defaultValue value="pre">pre</option>
                                        <option defaultValue value="post">post</option>

                                    </Form.Control>
                                </Form.Row>
                                {/* <Form.Row>
                                    <Col>
                                        <Form.Label>Image Upload: <span className="asterisk"> *</span></Form.Label><br></br>
                                        {this.state.ImageView ?
                                            <Figure>
                                                <Figure.Image src={this.state.ImageView} thumbnail width={171} height={180} />
                                            </Figure> : <p>No image selected</p>}
                                        <Form.File
                                            name="image"
                                            label="Background Image Upload"
                                            accept="image/*"
                                            onChange={this.onBannerBackgroundImageChange}
                                            custom
                                        />
                                    </Col>
                                </Form.Row> */}

                                {/* Image url */}
                                <Form.Row>
                                    <Form.Label>Image URL:</Form.Label>
                                    <Form.Control type="text" placeholder=" Image URL" name="image_url" value={image_url} onChange={this.changeHandler} />
                                </Form.Row>
                                {/* <Form.Row>
                                    <Col>
                                        <Form.Label> Audio File<span style={{ color: "red" }}>*</span> </Form.Label><br></br>
                                        <Form.File name="audio_file" onChange={this.onAudioFileChange} accept=".mp3" />
                                        <Form.Text className="text-muted">"Supported file type: Only .mp3 .wav files are supported"</Form.Text>

                                    </Col>
                                </Form.Row> */}
                                {/* Audio Url */}
                                <Form.Row>
                                    <Form.Label>Audio URL:</Form.Label>
                                    <Form.Control type="text" placeholder="Audio URL" name="audio_url" value={audio_url} onChange={this.changeHandler} />
                                </Form.Row>
                            </Form.Group>

                            {this.props.update ? <Button variant="primary" onClick={this.updateHandler}>update Ad</Button> : <Button type="submit" disabled={this.state.pulishDisabled} variant="info">Publish</Button>}
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default CreateAds;